$Primary: #41559b;
$Accent: #da2052;
$Light: #fefff8;
$Dark: #051129;

::-webkit-scrollbar {
  width: 0px;
}

.buzz-grid-container {
  display: grid;
  column-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin-left: 20px;
  margin-right: 20px;
  height: 50vh;
}

.buzz-controls {
  grid-column-start: 1;
  grid-column-end: span 1;
  margin-left: 40px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.upper {
  height: 50vh;

  .inner-upper {
    width: 38%;
    padding-top: 10%;
    margin-left: auto;
    margin-right: auto;
  }
}

.lower {
  height: 100%;
  width: 100vw;
  grid-column-start: 2;
  grid-column-end: span 12;
  overflow: hidden;
  padding-top: 17px;

  .inner-lower {
    height: 100%;
    width: 91%;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
}

.buzz-text1 {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;

  .marquee1 {
    display: inline-block;
    font-family: "Poppins";
    font-style: normal;
    font-size: 95.95px;
    line-height: 75px;
    -webkit-text-stroke: 1px $Light;
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    animation: upscroll1 10s infinite linear;
  }

  .marquee2 {
    display: inline-block;
    font-family: "Poppins";
    font-style: normal;
    font-size: 95.95px;
    line-height: 75px;
    -webkit-text-stroke: 1px $Light;
    -webkit-text-fill-color: $Light;
    animation: upscroll2 10s infinite linear;
    animation-delay: 5s;
  }
}

@keyframes upscroll1 {
  0% {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}

@keyframes upscroll2 {
  0% {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
  }
  100% {
    transform: translateX(-200%);
    -webkit-transform: translateX(-200%);
  }
}

.buzz-text2 {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;

  .marquee1 {
    display: inline-block;
    font-family: "Poppins";
    font-style: normal;
    font-size: 36.65px;
    line-height: 55px;
    -webkit-text-stroke: 1px $Primary;
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    animation: downscroll1 10s infinite linear;
  }

  .marquee2 {
    display: inline-block;
    font-family: "Poppins";
    font-style: normal;
    font-size: 36.65px;
    line-height: 55px;
    -webkit-text-stroke: 1px $Primary;
    -webkit-text-fill-color: $Primary;
    animation: downscroll2 10s infinite linear;
    animation-delay: 5s;
  }
}

@keyframes downscroll1 {
  0% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
  100% {
    transform: translateX(110%);
    -webkit-transform: translateX(110%);
  }
}

@keyframes downscroll2 {
  0% {
    transform: translateX(-200%);
    -webkit-transform: translateX(-200%);
  }
  100% {
    transform: translateX(10%);
    -webkit-transform: translateX(10%);
  }
}

.blog-card {
  height: 100%;
  padding-left: 50px;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(10px);
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    h3 {
      color: $Primary;
      font-weight: bolder;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 8.65px;
      line-height: 13px;
      margin-top: 20px;
    }

    h4 {
      margin-top: 20px;
      width: 280px;
    }

    .thumbnail {
      // height: 65%;
      width: 65%;
      margin-bottom: -30px;
    }
  }

  .divider {
    border-left: 1px solid $Primary;
  }
}

a, a:visited, a:hover, a:active{
  color: inherit;
  text-decoration: none;
}

.arrow-sound {
  position: fixed;
  // position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
}