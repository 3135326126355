$Primary: #41559b;
$Accent: #da2052;
$Light: #fefff8;
$Dark: #051129;


.home-grid-container {
  display: grid;
  column-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr 4fr 1fr 1fr 1fr 1fr;
  //grid-auto-flow: column;
  margin-left: 20px;
  margin-right: 20px;
}

.rotating-mars-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  grid-column-start: 5;

  .rotating-mars {
    height: 300px;
    width: 300px;
    position: fixed;
    z-index: 302;
  }

  .mars {
    position: fixed;
    z-index: 302;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: url("../../Assets/images/mars-scape.jpg");
    background-size: 600px;
    background-position: center;
    background-color: #da2052;
    box-shadow: 
      inset -10px 0px 10px rgba(214,68,57, 0.5),
      inset 60px -20px 40px rgba(2, 16, 31, 1),
      inset 10px -10px 10px rgba(2, 16, 31, 1);
    transform-style: preserve-3d;
    transform: rotate(10deg);
    animation: rotate 10s linear infinite;
  }
  
  @keyframes rotate {
    0%   { background-position: 0 0; } 
    100% { background-position: 600px 0; }
  }

  .hero1{
    position: fixed;
    z-index: 303;
    padding-right: 50px;
  }
  
  .hero2 {
    position: fixed;
    z-index: 301;
    padding-left: 50px;
  }

  .ring0{
    position: fixed;
    z-index: 298;
    height: 70vh;
    width: 70vh;
    animation: rotation 16s infinite linear;
  }

  .ring1{
    position: fixed;
    z-index: 297;
    height: 70vh;
    width: 70vh;
    animation: rotation 10s infinite linear;
  }

  .ring2{
    position: fixed;
    z-index: 299;
    height: 60vh;
    width: 70vh;
    animation: rotation 8s infinite linear;
  }

  .ring3{
    position: fixed;
    z-index: 300;
    height: 50vh;
    width: 70vh;
    animation: rotation 5s infinite linear;
  }

  .inst-text {
    display: flex;
    margin-top: 85vh;

    p {
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 20px;
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.social-nav {
  list-style-type: none;
  grid-column-start: 2;
  padding: 0%;
  margin-top: auto;
  margin-bottom: auto;

  li {
    padding-top: 25px;
    padding-bottom: 25px;
    height: 20px;
    width: 20px;

    &:hover{

      svg path {
        stroke: $Light;
      }
    }
  }
}

.social-sound {
  position: fixed;
  height: 20px;
  width: 20px;
}

.scroll{
  width: 18px;
  height: 32px;

  .mouse{
    width: 100%;
    height: 100%;
    display: block;
    border: 2px solid $Primary;
    border-radius: 46px;

    .wheel{
      width: 3px;
      height: 8px;
      background: $Primary;
      display: block;
      border-radius: 30%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 4px;
      animation-name: scroll-wheel;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes scroll-wheel{
  from{
      transform: translate(0, 0);
      opacity: 1;
  }
  to{
      transform: translate(0, 10px);
      opacity: 0;
  }
}
