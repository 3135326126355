$Primary: #41559b;
$Accent: #da2052;
$Light: #fefff8;
$Dark: #051129;

.outer {
    height: 100vh;
    width: 100vw;

    img {
        height: 40%;
    }

    .viz {
        transition: 500ms ease-in;
        opacity: 1;
    }

    .inviz {
        transition: 500ms ease-out;
        opacity: 0;
    }
}

.cre-pos {
    position: absolute;
    top: 30%;
    right: 5%;
    z-index: 500;
}

.gepl-pos {
    position: absolute;
    top: 30%;
    right: 5%;
    z-index: 499;
}

.alf-pos {
    position: absolute;
    top: 30%;
    right: 5%;
    z-index: 498;
}

.sust-pos {
    position: absolute;
    top: 30%;
    right: 5%;
    z-index: 497;
}

.inner {
    width: 100vw;
    position: absolute;
    top: 100px;
    z-index: 501;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .strips {
        height: 70px;
        padding-top: 18px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        pointer-events: none;
        background: rgba(255, 255, 255, 0);
        transition: 500ms ease-in-out;

        &:hover {
            height: 160px;
            // background: rgba(255, 255, 255, 0.3);
            // backdrop-filter: blur(10px);
        }

        .piece {
            width: 50vw;
            height: 70px;
            overflow: hidden;
            pointer-events: auto;

            &:hover {
                height: 160px;
            }

            p {
                padding-top: 12px;
                padding-left: 50px;
            }
        }

        h3 {
            text-align: left;
            padding-left: 150px;

            img {
                float: right;
                padding-top: 10px;
            }

            .viz {
                transition: 500ms ease-in;
                opacity: 1;
            }
        
            .inviz {
                transition: 500ms ease-out;
                opacity: 0;
            }
        }
    

    }

    .divider {
        width: 100vw;
        border-bottom: 1px solid $Primary;
    }
}

a, a:visited, a:hover, a:active{
    color: inherit;
    text-decoration: none;
}