$Primary: #41559b;
$Accent: #da2052;
$Light: #fefff8;
$Dark: #051129;

.about-grid-container {
    display: grid;
    column-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-left: 20px;
    margin-right: 20px;
}

.team-container {
    display: flex;
    height: 100vh;
    align-items: center;
    grid-column-start: 1;
    grid-column-end: span 3;
    width: 378px;

    .team {
        display: flex;
        flex-wrap: wrap;
    
        .tint {
            color: $Accent;
            height: 106px;
            width: 106px;
            position: fixed;
            background: #da2052;
            opacity: 50%;
            mix-blend-mode: color;
            transition: 500ms ease-in-out;

            &:hover {
                opacity: 0;
            }

        }
        img {
            height: 106px;
            width: 106px;
            padding-right: 20px;
        }

        p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 8.65px;
            line-height: 13px;
            margin-top: 0px;
            padding-bottom: 20px;
          }
    }
}

.message-container {
    grid-column-start: 4;
    grid-column-end: span 6;
    display: flex;
    align-items: center;
    justify-content: center;

    .message {
        width: 650px;

        .bigtext {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 59.3px;
            margin: 0;
            display: inline;
            line-height: 40px;
        }
        
        h4 {
            display: inline;
        }
    }
}