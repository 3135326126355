$Primary: #41559b;
$Accent: #da2052;
$Light: #fefff8;
$Dark: #051129;

.services-grid-container {
  display: grid;
  column-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 2fr 2fr 2fr 1fr 1fr 1fr;
  //grid-auto-flow: column;
  margin-left: 20px;
  margin-right: 20px;
}

.card-container1 {
  grid-column-start: 4;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-container2 {
  grid-column-start: 5;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container3 {
  grid-column-start: 6;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.card {
  width: 200px;
  height: 250px;
  margin-top: 50px;
  background: linear-gradient(
    147.38deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(10px);
  overflow: hidden;
  transition-property: width, height;
  transition-duration: 0.5s;

  .title-container {
    padding-left: 10px;
    padding-top: 10px;

    p {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      transition-property: font-size, line-height;
      transition-duration: 0.5s;
    }

    img {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  .disc-container {
    width: 90%;
    // padding-left: 10px;
    // padding-top: 10px;
    position: absolute;
    top: 25%;
    left: 10px;
    z-index: 1;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 8.65px;
      line-height: 13px;
      transition-property: font-size, line-height;
      transition-duration: 0.5s;
    }
  }

  .title-accent {
    position: absolute;
    left: -1px;
    bottom: -6px;
    z-index: 0;

    h3 {
      font-weight: 700;
      line-height: 40px;
      color: #040C1C;
      -webkit-text-stroke: 2px #040C1C;
    }
  }

  &:hover {
    width: 250px;
    height: 313px;

    .title-container {
      p {
        font-weight: bolder;
        font-size: 22.65px;
        line-height: 34px;
      }
    }

    .disc-container {
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}
